import React from 'react';

import CategoryPage from '../../components/CategoryPage';

const Weeding = () => (
  <CategoryPage
    title="Bebé - Sessão de Acompanhamento"
    totalItems={3}
    s3Path={`portfolio/babies/`}
    internalPath={`/bebes/`}
    nextPath={`/familia`}
    nextLabel="Família"
    prevPath={`/smash-the-cake`}
    prevLabel="Smash the Cake"
  />
)

export default Weeding;
